import styled from 'react-emotion';
import tinyColor from 'tinycolor2';

import { theme, } from '@ezugi/bootstrap';

const { palette, tints, } = theme;

const BORDER_COLOR = tinyColor(palette.foreground)
  .setAlpha(tints.light)
  .toString();

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 35px;
`;

export const Label = styled.div`
  background: ${palette.primary};
  color: ${palette.primaryContrast};
  padding: 8px 25px 8px 25px;
  font-size: 22px;
  font-family: 'Roboto';
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
`;
